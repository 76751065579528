$enable-rounded: false;
$border-radius: 0px;
$border-width: 0;
$primary: rgb(48, 70, 50);
$secondary: #7b4320;
$light: #dfe6ee;
$dark: #646464;
$grid-columns: 12;
$body-color: $dark;
$transition-base: all .25s ease-in-out;
$transition-fade: opacity .25s linear;
$transition-collapse: height .25s ease;
$btn-transition: color .25s ease-in-out,
background-color .25s ease-in-out,
border-color .25s ease-in-out,
box-shadow .25s ease-in-out;
$input-transition: border-color .25s ease-in-out,
box-shadow .25s ease-in-out;
$custom-forms-transition: background-color .25s ease-in-out,
border-color .25s ease-in-out,
box-shadow .25s ease-in-out;
$modal-transition: transform .25s ease-out;